import create from 'zustand';
import React, { useEffect, useState } from 'react';

interface UserProfile {
    onboarded: boolean | null;
    company: string | null;
    title: string | null;
    phone: string | null;
    industry: string | null;
    timezone: string | null;
    country: string | null;
}

interface UserSubscription {
    plan: string | null;
    status: string | null;
    starts: string | null;
    ends: Date | null;
}



interface Account {
    name: string;
    slug: string;
    id: string;
    endpoint: string;
}

export interface Membership {
    account: Account
    current: boolean
}

export interface User {
    name?: string | null;
    email?: string | null;
    state?: string | null;
    picture?: string | null;
    profile?: UserProfile;
    subscription?: UserSubscription | null;
    memberships?: Array<Membership>;
}

export interface Task {
    uuid: string;
    code: number;
    title: string;
    state: string;
    stage: string;
    description: string;
    date_created: Date;
    time: string;
    variant: string;
}

interface Tasks {
    tasks: Array<Task>;
    stats: {
        total: number;
        draft: number;
        todo: number;
        active: number;
        completed: number;
    };
}


interface CheckMetadata {
    name: string;
    title?: string;
    labels?: Object
}

interface CheckSpec {
    frequency: string;
    crontab: string;
    locations: Array<string>;
    checks: Array<Object>;
}

interface Check {
    apiVersion: string;
    kind: string;
    metadata: CheckMetadata;
    spec: CheckSpec;
}


interface UsageStats {
    date: string;
    count: number;
    status: string;
}

interface Usage {
    projects: number;
    checks: number;
    runs_this_period: number;
    failing_checks: number;
    counts: Array<number>;
    period_usage: number;
    period_elapsed: number;
    stats: Array<UsageStats>;
}

export interface AppState {
    user: User;
    setUser: (user: User) => void;
    tasks: Tasks | null;
    setTasks: (tasks: Tasks) => void;
    checks: Array<Check> | null;
    setChecks: (checks: Array<Check>) => void;
    usage: Usage | null;
    setUsage: (usage: Usage) => void;
}

const ANONYMOUS = {
    name: null,
    state: null,
    email: null,
    picture: null,
    profile: {
        onboarded: false,
        company: '',
        title: '',
        phone: '',
        industry: '',
        timezone: '',
        country: '',
    },
};

export const useAppStore = create<AppState>((set) => ({
    user: ANONYMOUS,
    tasks: null,
    setUser: (user: User) => set({ user }),
    setTasks: (tasks: Tasks) => set({ tasks }),
    checks: null,
    setChecks: (checks: Array<Check>) => set({ checks }),
    usage: null,
    setUsage: (usage: Usage) => set({ usage }),
}));

export const doLoadChecks = () => {
    const foo = fetch('/api/checks')
        .then((response) => response.json())
        .then((data) => {
            const checks = data.resources;
            return data.resources;
            
        });
    return foo;
};

const StateWrapper: React.FC = (props) => {
    const setUser = useAppStore((state: AppState) => state.setUser);
    const [user, setTasks] = useAppStore((state: AppState) => [state.user, state.setTasks]);
    const setChecks = useAppStore((state: AppState) => state.setChecks);
    const checks = useAppStore((state: AppState) => state.checks);
    const setUsage = useAppStore((state: AppState) => state.setUsage);

    // const setErrors = useAppStore((state) => state.setErrors);

    const [loadingTasks, setLoadingTasks] = useState(false);
    const loadTasks = () => {
        if (!loadingTasks) {
            setLoadingTasks(true);
            doLoadChecks().then((checks) => {
                setChecks(checks);
                setLoadingTasks(false);
            });
        }
    };

    useEffect(() => {
        fetch('/api/auth/me/')
            .then((response) => response.json())
            .then((data) => {
                setUser(data);
            });
    }, [setUser]);

    useEffect(() => {
        if (user && user.email && !loadingTasks) {
            loadTasks();
        }
    }, [setTasks, user]);

    useEffect(() => {
        fetch('/api/checks/usage')
            .then((response) => response.json())
            .then((data) => {
                setUsage(data);
            });
    }, [setUsage]);

    return (
        <>
            {user !== null && props.children}
            {user === null && <i>Loading...</i>}
        </>
    );
};

export default StateWrapper;
